import "./produkte.css";
import React from "react";
import Card from "./card.js";

export function Produkte() {
  return (
    <>
      <div className="background-products">
        <div className="background-bottom">
        <div className="spacerp"></div>
        <div className="specials">Ausgewählte und neue Produkte</div>
        <div className="card-container">
          <div className="card">
            <Card></Card>
            <Card></Card>
            <Card></Card>
            <Card></Card>
            {/* <div className="card-product">
              <div className="card-img-p"></div>
              <div className="card-name-p">Coming Soon</div>
              <div className="card-rank-p"></div>
              <div className="card-price-p"></div>
            </div>

            <div className="card-product">
              <div className="card-img-p"></div>
              <div className="card-name-p">Coming Soon</div>
              <div className="card-rank-p"></div>
              <div className="card-price-p"></div>
            </div>

            <div className="card-product">
              <div className="card-img-p"></div>
              <div className="card-name-p">Coming Soon</div>
              <div className="card-rank-p"></div>
              <div className="card-price-p"></div>
            </div> */}
          </div>
        </div>
        <div className="questions-p">Fragen? info@zapfhahn.eu</div>
        </div>
      </div>
    </>
  );
}
