import "./App.css";
import { Produkte } from "./pages/Produkte";
import { Team } from "./pages/Team";
import { Events } from "./pages/Events";
import { Datenschutz } from "./pages/Datenschutz";
import { Home } from "./pages/Home";
import { Link, Route, Routes } from "react-router-dom";
import logo from "./logo.png";
import { Impressum } from "./pages/Impressum";
import Footer from "./pages/Footer.js";

function App() {
  return (
    <>
    {/* <div className="home-wrapper"> */}
      <nav className="nav">
        <div className="landscape">
          <ul className="ul">
            <Link to="/" className="comp-name">
              <img
                classname="logo"
                src={logo}
                alt="logo"
                style={{ width: "50px" }}
              />
            </Link>
            <li className="li-team">
              <Link to="/team">Team</Link>
            </li>
            <li className="li-produkte">
              <Link to="/produkte">Produkte</Link>
            </li>
            <li className="li-events">
              <Link to="/events">Events</Link>
            </li>
          </ul>
        </div>
        <div className="portrait">
          <ul className="ul">
            <Link to="/" className="comp-name">
              <img
                classname="logo"
                src={logo}
                alt="logo"
                style={{ width: "50px" }}
              />
            </Link>
            <label className="hamburger-menu">
              <input type="checkbox"></input>
            </label>
            <aside className="sidebar-hamb">
              <li className="li-team-hamb">
                <Link to="/team">Team</Link>
              </li>
              <li className="li-produkte-hamb">
                <Link to="/produkte">Produkte</Link>
              </li>
              <li className="li-events-hamb">
                <Link to="/events">Events</Link>
              </li>
            </aside>
          </ul>
        </div>
      </nav>

      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/team" element={<Team />}></Route>
        <Route path="/produkte" element={<Produkte />}></Route>
        <Route path="/events" element={<Events />}></Route>
        <Route path="/datenschutz" element={<Datenschutz />}></Route>
        <Route path="/impressum" element={<Impressum />}></Route>
      </Routes>

      <Footer></Footer>
      {/* </div> */}
    </>
  );
}

export default App;
