import "./team.css";
import team from "../team.jpg";

export function Team() {
  return (
    <>
      <div className="background">
        <div className="spacert"></div>

        <div className="card-sales1">
          <img src={team} className="card-img" alt="team"></img>

          <div className="card-name">
            Marion Bedau | Torben Bedau | Beate Neumann
          </div>
          <div className="card-rank">
            Verkäuferin | Geschäftsführer | Verkäuferin
          </div>

          <div className="card-mail"></div>
          <div className="questions-t">Fragen? info@zapfhahn.eu</div>
        </div>
      </div>
    </>
  );
}
