import "./cards.css";
import React from "react";
import wine from "./wine.png";


export default function Card() {
    return (
        <>
            <div className="game">

            <div class="rank">NEU</div>

            <div className="front">
                <img
                    className="img-game"
                    src={wine}
                    alt="logo"
                     style={{ borderRadius: ".5em" }}
                />
                <h3 className="name">Coming Soon</h3>
                {/* <div className="stats">
                    <p className="viewers">0k</p>
                    <div className="streamers">
                        <img
                        className="img-streamer"
                        src={over}
                        alt="logo"
                        // style={{ width: "50px" }}
                         />
                    </div>
                </div> */}
            </div>

            <div className="back">
                {/* <div className="streaming-info">
                    <p className="game-stat">12k<span className="span">Watching</span></p>
                    <p className="game-stat">21k<span className="span">Streams</span></p>
                </div>
                <button className="btn">See More</button> */}
                <div className="streamers-back">
                    <div className="streamer">
                        <p className="name">Coming Soon</p>
                        <p className="num">€</p>
                    </div>
                </div>
            </div>

            <div className="card-background"></div>

                </div>
        </>
        );
    }