import "./datenschutz.css";

export function Datenschutz() {
  return (
    <>
      <div className="spacerd"></div>
      <div className="daten-wrapper">
        <div className="daten-content">
          <div className="d-heading">Datenschutzerklärung</div>
          <div className="spacerd2"></div>
          <div className="d-subheading">1. Datenschutz auf einen Blick</div>
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-miniheading">Allgemeine Hinweise</div>
          <div className="spacerd3"></div>
          Die folgenden Hinweise geben einen einfachen Überblick darüber, was
          mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website
          besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie
          persönlich identifiziert werden können. Ausführliche Informationen zum
          Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten
          Datenschutzerklärung.
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-miniheading">Datenerfassung auf dieser Website</div>
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-microheading">
            Wer ist verantwortlich für die Datenerfassung auf dieser Website?
          </div>
          <div className="spacerd3"></div>
          Die Datenverarbeitung auf dieser Website erfolgt durch den
          Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt
          „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung
          entnehmen.
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          Wie erfassen wir Ihre Daten?
          <div className="spacerd3"></div>
          Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese
          mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein
          Kontaktformular eingeben.
          <div className="spacerd3"></div> Andere Daten werden automatisch oder
          nach Ihrer Einwilligung beim Besuch der Website durch unsere
          IT-Syssteme erfasst. Das sind vor allem technische Daten (z. B.
          Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die
          Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website
          betreten.
          <div className="spacerd3"></div>
          Wofür nutzen wir Ihre Daten?
          <div className="spacerd3"></div>
          Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung
          der Website zu gewährleisten. Andere Daten können zur Analyse Ihres
          Nutzerverhaltens verwendet werden.
          <div className="spacerd3"></div>
          Welche Rechte haben Sie bezüglich Ihrer Daten?
          <div className="spacerd3"></div>
          Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft,
          Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu
          erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung
          dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur
          Datenverarbeitung erteilt haben, können Sie diese Einwilligung
          jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht,
          unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein
          Beschwerderecht bei der zuständigen Aufsichtsbehörde zu.
          <div className="spacerd3"></div>
          Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich
          jederzeit an uns wenden.
          <div className="spacerd3"></div>
          <div className="d-miniheading">
            Analyse-Tools und Tools von Drittanbietern
          </div>
          <div className="spacerd3"></div>
          Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch
          ausgewertet werden. Das geschieht vor allem mit sogenannten
          Analyseprogrammen. Detaillierte Informationen zu diesen
          Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-subheading">2. Hosting </div>
          <div className="spacerd3"></div>
          Wir hosten die Inhalte unserer Website bei folgendem Anbieter:
          <div className="spacerd3"></div>
          <div className="d-miniheading">Externes Hosting</div>
          <div className="spacerd3"></div>
          Diese Website wird extern gehostet. Die personenbezogenen Daten, die
          auf dieser Website erfasst werden, werden auf den Servern des Hosters
          / der Hoster gespeichert. Hierbei kann es sich v. a. um IP-Adressen,
          Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten,
          Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine
          Website generiert werden, handeln.
          <div className="spacerd3"></div>
          Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber
          unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b
          DSGVO) und im Interesse einer sicheren, schnellen und effizienten
          Bereitstellung unseres Online-Angebots durch einen professionellen
          Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende
          Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich
          auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG,
          soweit die Einwilligung die Speicherung von Cookies oder den Zugriff
          auf Informationen im Endgerät des Nutzers (z. B.
          Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung
          ist jederzeit widerrufbar.
          <div className="spacerd3"></div>
          Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten,
          wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und
          unsere Weisungen in Bezug auf diese Daten befolgen.
          <div className="spacerd3"></div>
          Wir setzen folgende(n) Hoster ein:
          <div className="spacerd3"></div>
          Pein GbR Käthe-Kollwitz-Weg 24 23558 Lübeck
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-miniheading">Auftragsverarbeitung </div>
          <div className="spacerd3"></div>
          Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung
          des oben genannten Dienstes geschlossen. Hierbei handelt es sich um
          einen datenschutzrechtlich vorgeschriebenen Vertrag, der
          gewährleistet, dass dieser die personenbezogenen Daten unserer
          Websitebesucher nur nach unseren Weisungen und unter Einhaltung der
          DSGVO verarbeitet.
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-subheading">
            3. Allgemeine Hinweise und Pflichtinformationen{" "}
          </div>
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-miniheading">Datenschutz </div>
          <div className="spacerd3"></div>
          Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten
          sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und
          entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser
          Datenschutzerklärung.
          <div className="spacerd3"></div>
          Wenn Sie diese Website benutzen, werden verschiedene personenbezogene
          Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
          persönlich identifiziert werden können. Die vorliegende
          Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir
          sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das
          geschieht.
          <div className="spacerd3"></div>
          Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B.
          bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
          Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
          nicht möglich.
          <div className="spacerd3"></div>
          <div className="d-miniheading">
            Hinweis zur verantwortlichen Stelle
          </div>{" "}
          <div className="spacerd3"></div>
          Die verantwortliche Stelle für die Datenverarbeitung auf dieser
          Website ist:
          <div className="spacerd3"></div>
          Zapfhahn | Torben bedau
          <br />
          Markttwiete 4
          <br />
          23611 Bad Schwartau
          <div className="spacerd3"></div>
          Telefon: 0451/23030
          <br />
          E-Mail: torben.bedau@gmail.com
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          Verantwortliche Stelle ist die natürliche oder juristische Person, die
          allein oder gemeinsam mit anderen über die Zwecke und Mittel der
          Verarbeitung von personenbezogenen Daten (z. B. Namen, E-Mail-Adressen
          o. Ä.) entscheidet.
          <div className="spacerd3"></div>
          <div className="d-miniheading">Speicherdauer</div>
          <div className="spacerd3"></div>
          Soweit innerhalb dieser Datenschutzerklärung keine speziellere
          Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten
          bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie
          ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung
          zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern
          wir keine anderen rechtlich zulässigen Gründe für die Speicherung
          Ihrer personenbezogenen Daten haben (z. B. steuer- oder
          handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall
          erfolgt die Löschung nach Fortfall dieser Gründe.
          <div className="spacerd3"></div>
          <div className="d-miniheading">
            Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung
            auf dieser Website{" "}
          </div>
          <div className="spacerd3"></div>
          Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten
          wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit.
          a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere
          Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle
          einer ausdrücklichen Einwilligung in die Übertragung personenbezogener
          Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf
          Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die
          Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr
          Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt
          die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG.
          Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur
          Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs.
          1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese
          zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf
          Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann
          ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs.
          1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen
          Rechtsgrundlagen wird in den folgenden Absätzen dieser
          Datenschutzerklärung informiert.
          <div className="spacerd3"></div>
          <div className="d-miniheading">
            Widerruf Ihrer Einwilligung zur Datenverarbeitung
          </div>
          <div className="spacerd3"></div>
          Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen
          Einwilligung möglich. Sie können eine bereits erteilte Einwilligung
          jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
          erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.
          <div className="spacerd3"></div>
          <div className="d-miniheading">
            Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie
            gegen Direktwerbung (Art. 21 DSGVO)
          </div>{" "}
          <div className="spacerd3"></div>
          WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER
          F DSGVO ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH
          AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER
          PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN
          AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE
          RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE
          DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR
          IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES
          SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE
          VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN
          ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG
          ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS.
          1 DSGVO).
          <div className="spacerd3"></div>
          WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU
          BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE
          VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE
          DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING,
          SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE
          WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT
          MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21
          ABS. 2 DSGVO).
          <div className="spacerd3"></div>
          <div className="d-miniheading">
            Beschwerderecht bei der zuständigen Aufsichtsbehörde{" "}
          </div>
          <div className="spacerd3"></div>
          Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein
          Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem
          Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes
          oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht
          besteht unbeschadet anderweitiger verwaltungsrechtlicher oder
          gerichtlicher Rechtsbehelfe.
          <div className="spacerd3"></div>
          <div className="d-miniheading">Recht auf Datenübertragbarkeit</div>
          <div className="spacerd3"></div>
          Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung
          oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich
          oder an einen Dritten in einem gängigen, maschinenlesbaren Format
          aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an
          einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es
          technisch machbar ist.
          <div className="spacerd3"></div>
          <div className="d-miniheading">
            Auskunft, Berichtigung und Löschung
          </div>
          <div className="spacerd3"></div>
          Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit
          das Recht auf unentgeltliche Auskunft über Ihre gespeicherten
          personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck
          der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder
          Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema
          personenbezogene Daten können Sie sich jederzeit an uns wenden.
          <div className="spacerd3"></div>
          <div className="d-miniheading">
            Recht auf Einschränkung der Verarbeitung
          </div>
          <div className="spacerd3"></div>
          Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit
          an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in
          folgenden Fällen:
          <div className="spacerd3"></div>
          Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen
          Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu
          überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die 5 / 8
          Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
          <div className="spacerd3"></div>
          Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig
          geschah/geschieht, können Sie statt der Löschung die Einschränkung der
          Datenverarbeitung verlangen.
          <div className="spacerd3"></div>
          Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie
          jedoch zur Ausübung, Verteidigung oder Geltendmachung von
          Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung
          die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
          verlangen.
          <div className="spacerd3"></div>
          Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben,
          muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen
          werden. Solange noch nicht feststeht, wessen Interessen überwiegen,
          haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer
          personenbezogenen Daten zu verlangen.
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt
          haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit
          Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung
          von Rechtsansprüchen oder zum Schutz der Rechte einer anderen
          natürlichen oder juristischen Person oder aus Gründen eines wichtigen
          öffentlichen Interesses der Europäischen Union oder eines
          Mitgliedstaats verarbeitet werden.
          <div className="spacerd3"></div>
          <div className="d-miniheading">SSL- bzw. TLS-Verschlüsselung</div>
          <div className="spacerd3"></div>
          Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der
          Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder
          Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw.
          TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran,
          dass die Adresszeile des Browsers von „http://“ auf „https://“
          wechselt und an dem Schloss-Symbol in Ihrer Browserzeile.
          <div className="spacerd3"></div>
          Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die
          Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen
          werden.
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-subheading">
            4. Datenerfassung auf dieser Website
          </div>
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-miniheading">Cookies</div>
          <div className="spacerd3"></div>
          Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind
          kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an.
          Sie werden entweder vorübergehend für die Dauer einer Sitzung
          (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem
          Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs
          automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät
          gespeichert, bis Sie diese selbst löschen oder eine automatische
          Löschung durch Ihren Webbrowser erfolgt.
          <div className="spacerd3"></div>
          Cookies können von uns (First-Party-Cookies) oder von Drittunternehmen
          stammen (sog. Third-PartyCookies). Third-Party-Cookies ermöglichen die
          Einbindung bestimmter Dienstleistungen von Drittunternehmen innerhalb
          von Webseiten (z. B. Cookies zur Abwicklung von
          Zahlungsdienstleistungen).
          <div className="spacerd3"></div>
          Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind
          technisch notwendig, da bestimmte Webseitenfunktionen ohne diese nicht
          funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von
          Videos). Andere Cookies können zur Auswertung des Nutzerverhaltens
          oder zu Werbezwecken verwendet werden.
          <div className="spacerd3"></div>
          Cookies, die zur Durchführung des elektronischen
          Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen
          erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur
          Optimierung der Website (z. B. Cookies zur Messung des Webpublikums)
          erforderlich sind (notwendige Cookies), werden auf Grundlage von Art.
          6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage
          angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an
          der Speicherung von notwendigen Cookies zur technisch fehlerfreien und
          optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung
          zur Speicherung von Cookies und vergleichbaren
          Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung
          ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a
          DSGVO und § 25 Abs. 1 6 / 8 TTDSG); die Einwilligung ist jederzeit
          widerrufbar.
          <div className="spacerd3"></div>
          Sie können Ihren Browser so einstellen, dass Sie über das Setzen von
          Cookies informiert werden und Cookies nur im Einzelfall erlauben, die
          Annahme von Cookies für bestimmte Fälle oder generell ausschließen
          sowie das automatische Löschen der Cookies beim Schließen des Browsers
          aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität
          dieser Website eingeschränkt sein.
          <div className="spacerd3"></div>
          Welche Cookies und Dienste auf dieser Website eingesetzt werden,
          können Sie dieser Datenschutzerklärung entnehmen.
          <div className="spacerd3"></div>
          <div className="d-miniheading">
            Anfrage per E-Mail, Telefon oder Telefax
          </div>
          <div className="spacerd3"></div>
          Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre
          Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten
          (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
          gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre
          Einwilligung weiter.
          <div className="spacerd3"></div>
          Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1
          lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
          zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen
          erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf
          unserem berechtigten Interesse an der effektiven Bearbeitung der an
          uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer
          Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt
          wurde; die Einwilligung ist jederzeit widerrufbar.
          <div className="spacerd3"></div>Die von Ihnen an uns per
          Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sie uns zur
          Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder
          der Zweck für die Datenspeicherung entfällt (z. B. nach
          abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche
          Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben
          unberührt.
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-subheading"> 5. Plugins und Tools</div>
          <div className="spacerd3"></div>
          <div className="spacerd3"></div>
          <div className="d-miniheading">
            Google Fonts (lokales Hosting)
          </div>{" "}
          <div className="spacerd3"></div>
          Diese Seite nutzt zur einheitlichen Darstellung von Schriftarten so
          genannte Google Fonts, die von Google bereitgestellt werden. Die
          Google Fonts sind lokal installiert. Eine Verbindung zu Servern von
          Google findet dabei nicht statt.
          <div className="spacerd3"></div>
          Weitere Informationen zu Google Fonts finden Sie unter
          https://developers.google.com/fonts/faq und in der
          Datenschutzerklärung von Google:
          https://policies.google.com/privacy?hl=de.
          <div className="spacerd3"></div>Quelle: https://www.e-recht24.de
        </div>
      </div>
    </>
  );
}
