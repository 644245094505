import "./home.css";
import video from "../zh-video.mp4";

export function Home() {
  return (
    <>
      <div className="backgroundvideo">
        <video src={video} autoPlay loop muted></video>
      </div>
      <div className="hero-info-wrapper">
        <div className="hero-headline">Besuchen Sie uns!</div>
        <div className="open-offers-wrapper">
          <div className="open-hours-wrapper ">
            <div className="colored-hours-bg">
              <div className="open-times-wrapper">
                <div className="open-times">Öffnungszeiten</div>
              </div>
              <div className="days">Montag bis Mittwoch</div>
              <div className="times">09:30 - 18:00 Uhr</div>
              <div className="days">Donnerstag & Freitag</div>
              <div className="times">09:30 - 20:00 Uhr</div>
              <div className="days">Samstag</div>
              <div className="times">09:30 - 14:00 Uhr</div>
              <div className="street">Markttwiete 4</div>
              <div className="location">23611 Bad Schwartau</div>
            </div>
          </div>
          <div className="offers-bg-wrapper">
            <div className="offers-bg">
              <div className="offers-wrapper">
                Was darf es sein?
                <div className="offers-spacer"></div>
                <div className="offers-grouper">
                  <div className="offers1">Weißwein</div>
                  <div className="offers">Rotwein</div>
                  <div className="offers">Rosé</div>
                  <div className="offers">Brandy</div>
                  <div className="offers">Rum</div>
                  <div className="offers">Whiskey</div>
                  <div className="offers">Grappa</div>
                  <div className="offers">Brand</div>
                  <div className="offers">Liköre</div>
                  <div className="offers">Öl</div>
                  <div className="offers">Essig</div>
                  <div className="offers">Geschenkprodukte</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
