import React from "react"
import { Link } from "react-router-dom";
import "./footer.css";


export default function Footer() {
    return(
        <>
        <div className="footer">
                <ul className="footer-links-ul">
                <li className="datenschutz">
                    <Link to="/datenschutz">Datenschutz</Link>
                </li>
                <li className="impressum">
                    <Link to="/impressum">Impressum</Link>
                </li>
                <div className="maker">
                    Handcrafted by{" "}
                    <button className="maker-link" href="https://29design.info">
                    29design
                    </button>
                    <a> |Under construction|</a>
                </div>
                </ul>
            </div>
        </>
    )
}