import "./events.css";

export function Events() {
  return (
    <>
      <div className="background">
        <div className="spacere"></div>
        <div className="card-event1">
          <div className="event-name">Rum Tasting</div>
          <div className="card-info">Mit den Drunken Shipmates</div>
          <div className="card-date">26.08.2023</div>
        </div>
        {/* <div className="card-event2">
        <div className="card-img"></div>
        <div className="card-name">Rum 1</div>
        <div className="card-info">Infofeld</div>
        <div className="card-price">95,99€</div>
      </div>
      <div className="card-product3">
        <div className="card-img"></div>
        <div className="card-name">Likör 1</div>
        <div className="card-rank"></div>
        <div className="card-price">42,99€</div>
      </div> */}
        {/* <div className="questions">Fragen? info@zapfhahn-oh.de</div> */}
      </div>
    </>
  );
}
