import "./impressum.css";

export function Impressum() {
  return (
    <>
      <div className="spacerd"></div>
      <div className="impressum-wrapper">
        <div className="impressum-content">
          <div className="i-heading">Impressum</div>
          <div className="spaceri"></div>
          <div className="i-subheading">Angaben gemäß § 5 TMG </div>
          <div className="spacerd3"></div>
          Torben Bedau
          <br />
          Zapfhahn
          <br />
          Markttwiete 4
          <br />
          23611 Bad Schwartau
          <div className="spacerd3"></div>
          <div className="i-subheading">Kontakt</div>
          <div className="spacerd3"></div>
          Telefon: 0451/23030
          <br />
          E-Mail: torben.bedau@gmail.com
          <div className="spacerd3"></div>
          <div className="i-subheading">
            Verbraucherstreitbeilegung/Universalschlichtungsstelle
          </div>
          <div className="spacerd3"></div>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
          <div className="spacerd3"></div>
          Quelle: https://www.e-recht24.de
        </div>
      </div>
    </>
  );
}
